<div
  class="list-header mat-elevation-z8"
  [ngStyle]="{ 'grid-template-columns': columnWidths?.join(' '), 'padding-right': 'calc(2rem + ' + scrollbarWidth() + 'px)', background: background }">
  @for (column of columns; track column; let i = $index) {
    <div [class.section]="column.name" [class.section--first]="i === firstNamedIndex" [class.section--last]="i === lastNamedIndex">
      @if (i === 0 && leftTemplate) {
        <ng-container [ngTemplateOutlet]="leftTemplate"></ng-container>
      }
      @if (column.text) {
        <div class="text-wrapper">
          @if (column.text) {
            <p class="text">{{ column.text }}</p>
          }
        </div>
      }

      @if (column.name) {
        <p class="bold">{{ isTranslationPrefixGiven ? (translationPrefix + '.' + pascalcaseToKebabCase(column.name) | translate) : column.name }}</p>
      }

      @if (column.info) {
        <mat-icon class="column-info-icons" [matTooltip]="column.info">info_outlined</mat-icon>
      }

      @if (i === columns.length - 1 && rightTemplate) {
        <ng-template [ngTemplateOutlet]="rightTemplate"></ng-template>
      } @else {
        @if (column?.actions?.length > 0) {
          <coin-action-button [class.text]="!background" [class.white-text]="background" [noicon]="true" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
            {{ isTranslationPrefixGiven ? (translationPrefix + '.' + 'more-actions' | translate) : null }}
          </coin-action-button>
          <mat-menu #menu="matMenu">
            @for (action of column?.actions; track action) {
              <button class="text" (click)="onActionClick(action)" mat-menu-item>
                {{ translationPrefix + '.' + pascalcaseToKebabCase(action) + '-action' | translate }}
              </button>
            }
          </mat-menu>
        }
      }
    </div>
  }
</div>
